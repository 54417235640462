import { Component } from '@angular/core';
import { StoreFinderUtils } from '../../utils/storefinder.utils';
import { BasePage } from '../base.page';

@Component({
  selector: 'app-payment-partner-error',
  templateUrl: './payment-partner-error.component.html',
  styleUrls: ['./payment-partner-error.component.scss']
})
export class PaymentPartnerErrorComponent extends BasePage {
  
  onClick() {
    StoreFinderUtils.switchToAppAndCloseWindow();
  }
}