import { Injectable, NgModule } from '@angular/core';
import { BrowserModule, HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingBuyComponent } from './pages/landing-buy/landing-buy.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import * as Hammer from 'hammerjs';

import { TsfLibCoreFeModule } from '@modeso/tsf-lib-core-fe';
import { ModesoLocalizationConfig, ModesoLocalizationModule } from '@modeso/modeso-lib-localization-fe';
import { environment } from '../environments/environment';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PointOfSaleConfig, PointOfSaleFeModule } from '@modeso/tsf-lib-pointofsales-fe';
import { OneTimeStartUrl } from './pages/one-time-start-url/one-time-start-url.component';
import { DgoodsSessionModule, DgoodsSessionConfig, AUTH_INTERCEPTOR } from '@modeso/twint-lib-session-fe';
import { LanguageHandler } from '@modeso/modeso-lib-core-fe';
import { AuthInterceptor } from './utils/interceptors/auth.interceptor';
import { InjectedTextFeautureModule , InjectedTextPipe } from '@modeso/modeso-lib-core-fe';
import { SessionConfig } from './state/session-state/session.config';
import { SessionModule } from './state/session-state/session.module';
import { MainTileComponent } from './components/main-tile/main-tile.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { ProxyComponent } from './components/proxy/proxy.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { PaymentPartnerErrorComponent } from './pages/paymentPartnerError/payment-partner-error.component';
import { SessionExpiredPage } from './pages/session-expired-page/session-expired-page.component';
import { SessionOrchestrator } from './shared/orchestrator/orchestrator';


@Injectable()

export class HammerConfig extends HammerGestureConfig {

  override   buildHammer(element: HTMLElement): HammerManager {
    return new Hammer.Manager(element, {
     touchAction: 'pan-y',
     inputClass: Hammer.TouchInput,
     recognizers: [
       [Hammer.Swipe, {
         direction: Hammer.DIRECTION_VERTICAL
       }]
     ]
   });
 }
  
}
const localizationServiceConfig: ModesoLocalizationConfig = {
  apiGateway: environment.modeso.apiGateway,
  serviceId: 'localization',
};
const sessionConfig: DgoodsSessionConfig = {
  apiGateway: environment.modeso.apiGateway,
};


const pointofsaleConfig: PointOfSaleConfig = {
  apiGateway: environment.modeso.apiGateway,
  serviceId: 'pointofsale'
};

const sessionConfig2: SessionConfig = {
  apiGateway: environment.modeso.apiGateway,
  serviceId: 'twint'
};

@NgModule({
  declarations: [AppComponent, LandingBuyComponent, OneTimeStartUrl , MainTileComponent,ProxyComponent, 
     PaymentPartnerErrorComponent, SessionExpiredPage],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    InjectedTextFeautureModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    GoogleMapsModule,
    HammerModule,
    FormsModule,
    MatIconModule,
    TsfLibCoreFeModule,
    PointOfSaleFeModule.forRoot(pointofsaleConfig),
    ModesoLocalizationModule.forRoot(localizationServiceConfig),
    DgoodsSessionModule.forRoot(sessionConfig),
    SessionModule.forRoot(sessionConfig2),
    MatDialogModule, 
    MatFormFieldModule,
    MatInputModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
  ],
  exports:[    
    InjectedTextPipe
  ],
  providers: [
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageHandler, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: AUTH_INTERCEPTOR, useClass: SessionOrchestrator },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
