import { Component, OnInit, Injector } from '@angular/core';
import { BasePage } from 'projects/tsf-project-admin/src/app/pages/base.page';
import { ActivatedRoute, Router } from '@angular/router';
import { DgoodsSessionStoreProvider } from '@modeso/twint-lib-session-fe';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-one-time-start-url',
  templateUrl: './one-time-start-url.component.html',
  styleUrls: ['./one-time-start-url.component.scss'],
})
export class OneTimeStartUrl extends BasePage implements OnInit {
  constructor(
    injector: Injector,
    private sessionProvider: DgoodsSessionStoreProvider,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super(injector);
    let iosProtocol = this.route.snapshot.queryParamMap.get('returnAppScheme');
    const androidProtocol = this.route.snapshot.queryParamMap.get('returnAppPackage');
    if (iosProtocol === null || iosProtocol === undefined) {
      iosProtocol = 'iosProtocol';
    }
    const protocol = androidProtocol ? androidProtocol : iosProtocol;
    const device = androidProtocol ? 'android' : 'ios';
    localStorage.removeItem('protocol');
    localStorage.removeItem('device');
    localStorage.setItem('protocol', protocol);
    localStorage.setItem('device', device);
    const urltoken = this.route.snapshot.url[1].path;
    const category = this.route.snapshot.url[0].path;
    sessionStorage.setItem('category', category);
    this.sessionProvider.start({ checkInUrl: urltoken, issuer: protocol }).pipe(
      map((urlState: any) => {
        if (urlState.isValidUrl) {
          this.router.navigate(['/landing-buy']);
          return true;
        } else {
          return false;
        }
      })
    );
  }

  override ngOnInit() {
    super.ngOnInit();
    this.subscriptions.push(
      this.sessionProvider.getSessionState$().subscribe((state: any) => {
        sessionStorage.removeItem('isTwintUser');
        if (state.isValidUrl) {
          sessionStorage.removeItem('checkInDate');
          if (state.user) {
            sessionStorage.setItem('checkInDate', (state.checkInDate as Date).toString());
            sessionStorage.setItem('isTwintUser', 'true');
          }
          this.router.navigate(['../../']);
        }
      })
    );
  }
}
