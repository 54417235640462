import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Injector, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BasePage } from '../base.page';
import { IPointOfSaleVM, PointOfSalesProvider, SearchComponent } from '@modeso/tsf-lib-pointofsales-fe';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { NavigationService } from '../../utils/services/navigation.service';
import { StoreFinderUtils } from '../../utils/storefinder.utils';
import { ILegal, IPointOfSaleDto, ITranslatedCategory } from '@modeso/types__tsf-ms-pointofsales';
import { combineLatestWith, Observable } from 'rxjs';
import Debug from 'debug';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
declare var google:any;
export const vh = window.innerHeight * 0.01;

const debug = Debug('modeso:tsf-project-storefinder:LandingBuyComponent');
@Component({
  selector: 'app-landing-buy',
  templateUrl: './landing-buy.component.html',
  styleUrls: ['./landing-buy.component.scss'],
})
export class LandingBuyComponent extends BasePage implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('map', { static: false }) mapElement!: ElementRef;
  @ViewChild('search', {static: false}) search!: SearchComponent; 
  map!: google.maps.Map;
  currentLocation!: google.maps.LatLngLiteral;
  currentLocationMarker!: google.maps.Marker;
  currentLocationButton!: HTMLButtonElement;
  markers: any = [];
  isLocationEnabled: boolean = false;
  nearbyLocations: IPointOfSaleVM[] = [];
  pointOfSale!: IPointOfSaleVM | null;
  renderDetails: boolean = false;
  locations: IPointOfSaleVM[] = [];
  categoryName!: string;
  isValidDistance!: boolean;
  isMobile: boolean = false;
  markerCluster!: MarkerClusterer;
  filterNotApplied: boolean = true;
  hasSelectedCategory: any;
  isSwiped!: boolean;
  
  readonly currentLocationIcon: google.maps.Symbol = {
    path: google.maps.SymbolPath.CIRCLE,
    fillColor: '#4B65F9',
    fillOpacity: 1.0,
    strokeWeight: 4,
    strokeColor: '#ffffff',
    scale: 10,
  }
  watchId!: number;
  hasCategory!: boolean;
  mapOptions: google.maps.MapOptions = {
    styles: [{
        featureType: 'poi',
        stylers: [{ visibility: 'off' }]
    }],
    disableDefaultUI: true,
    panControl: true,
    gestureHandling: 'greedy',
    zoomControl: true,
    zoomControlOptions:{position:  google.maps.ControlPosition.RIGHT_BOTTOM},
    keyboardShortcuts: false,
  }
  showFilter$: Observable<boolean>;
  tileTitle!: string;
  showTile: boolean = true;


  constructor(injector: Injector, 
    navigationService: NavigationService, 
    private ref: ChangeDetectorRef, 
    private pointOfSaleProvider: PointOfSalesProvider,
    private responsive: BreakpointObserver,
    private ngZone: NgZone,
  ) {
    super(injector, navigationService);
    this.subscriptions.push(this.responsive.observe([Breakpoints.Handset, Breakpoints.TabletPortrait])
    .subscribe(result => {
      if (result.matches) {
        this.isMobile = true;
        this.mapOptions = {...this.mapOptions, mapTypeControl: false, zoomControl: false}
        this.map?.setOptions(this.mapOptions);
      } else {
        this.isMobile = false;
        this.mapOptions = 
        {...this.mapOptions, zoomControl: true, 
          zoomControlOptions: {position: google.maps.ControlPosition.RIGHT_BOTTOM }} 
          this.map?.setOptions(this.mapOptions);
      }
     
      
    }));

    this.subscriptions.push(this.pointOfSaleProvider.getUserLocationState$()
    .subscribe((locationState)=> this.isLocationEnabled = locationState));

    this.showFilter$ = this.pointOfSaleProvider.getCurrentFilterVisibilityState$();
  }

  ngAfterViewInit(): void {
    this.map = new google.maps.Map(this.mapElement.nativeElement, this.mapOptions);
    google.maps.event.addListener(this.map as any, 'dragend', () => {
      this.ngZone.run(() => {
        this.setNearby();
      });
     
    });
    google.maps.event.addListener(this.map as any, 'zoom_changed', () => {
      this.ngZone.run(() => {
        this.setNearby();
      });
     
    });


    google.maps.event.addListener(this.map as any, 'click', () => {
      this.pointOfSaleProvider.dispatchApplyFilterVisibility(false);       
    });

    this.setMyCurrentPosition();
    this.handleNavigatorLocationPermission();
  }
  
  override ngOnInit(): void {
    super.ngOnInit();
   
    this.categoryName = sessionStorage.getItem('category') || 'all';
    this.hasCategory = this.categoryName !== 'all';
    this.pointOfSaleProvider.dispatchGetAllPointOfSales$(this.categoryName);
    this.pointOfSaleProvider.dispatchGetTranslatedCategories();
    const legals$ = this.pointOfSaleProvider.getLegals$();
    const categories$ = this.pointOfSaleProvider.getTranslatedCategories$();
    const pointOfSales$ = this.pointOfSaleProvider.getAllPointOfSales$();
    const isCategorySelected$ = this.pointOfSaleProvider.getSelectedCategoriesIds$();
    this.subscriptions.push(
      pointOfSales$.pipe(
        combineLatestWith(legals$, categories$)
      ).subscribe(
        ([pointOfSales, legals, categories]) => {
          if(categories && categories.length && this.hasCategory) {
            const categoryFilteredFrom = categories.find((x) => x.name === this.categoryName);
            if(categoryFilteredFrom) {
              this.tileTitle = categoryFilteredFrom.tileTitle as string;
              this.pointOfSaleProvider.dispatchFilterPosByCategory([categoryFilteredFrom.categoryId]);
            }  
          }
          this.clearMarkersOnMap();
          this.populateLocations(pointOfSales, legals, categories!);
          this.addMarkers();
          this.setNearby();
        }
      )
    );
    this.subscriptions.push(isCategorySelected$.subscribe((value) => {
      if(value && value.length) this.hasSelectedCategory = true;
      else this.hasSelectedCategory = false;
    }));
  }

  private populateLocations(pointOfSales: IPointOfSaleDto[], legals: ILegal[], categories: ITranslatedCategory[]) {
    if(!pointOfSales || !legals.length || !categories || !categories.length) {
      return;
    }

    this.locations = pointOfSales.map((point) => {
      debug(legals);
      debug(categories);
      
      const legal = legals?.filter((legal) => legal.legalId === point.legal)[0];
      const category = categories?.filter((category) => category.categoryId === point.category)[0];
      
      return {
        pointName: point.name,
        pointAddress: {
          street: point.address.street,
          zipCode: point.address.zipCode,
          city: point.address.city
        },
        lat: point.location?.coordinates[1],
        lng: point.location?.coordinates[0],
        isOpenNow: false,
        website: point.website,
        phone: point.phone,
        logo: legal?.icon,
        mapIcon: category?.icon,
        categoryText: category?.buttonText,
        legalId: point.legal,
        pointofsaleId: point.pointofsaleId,
        openingHours: point.openingHours as any,
        categoryName:  category.description
      } as IPointOfSaleVM;
    });
  }

  public jumpBackToMyCurrentPosition() {
    this.map.setCenter(this.currentLocation);
    this.map.setZoom(15);
  }

  onRouteClicked(pointOfSale?: IPointOfSaleVM) {
    if(!this.isLocationEnabled) return;
    const pointOfSaleToRouteTo = pointOfSale || this.pointOfSale;
    const pointAddress = `${pointOfSaleToRouteTo!.pointAddress.street}, ${pointOfSaleToRouteTo!.pointAddress.zipCode} 
      ${pointOfSaleToRouteTo!.pointAddress.city}`
    const link = 
    `https://www.google.com/maps/dir/?api=1&origin=${this.currentLocation.lat},${this.currentLocation.lng}
    &destination=${pointAddress}`;
    window.location.href = link;
  }

  private addMarkers() {
    if(!this.locations.length){
      return;
    }
    this.markers = [];
    for (const location of this.locations) {
      const marker = new google.maps.Marker({
        position: location,
        map: this.map,
        icon: {
          url: location.mapIcon,
          scaledSize: new google.maps.Size(25, 35)
        }
      });
      marker.addListener('click', () => {
        this.renderPointOfSaleDetails(location);
      })
      this.markers.push(marker);
    }
    this.setClusters();
  }

  public backToList() {
    this.renderDetails = false;
    this.pointOfSale = null;
    this.ref.detectChanges();
  }

  public setAllCategories() {
    this.filterNotApplied = false;
  }

  public viewResult() {
    this.filterNotApplied = false;
    this.pointOfSaleProvider.applyFilterPosByCategory();
  }

  public isTileSwiped(event:any){
    this.isSwiped = event
  }
  private clearMarkersOnMap() {
    if(this.markerCluster)
      this.markerCluster.setMap(null)
    for (let i = 0; i < this.markers.length; i++) {
     this.markers[i].setMap(null);
    }
  }
  private setNearby() {
    if(this.isLocationEnabled){ 
      this.nearbyLocations = [];
      this.locations.forEach((point: IPointOfSaleVM) => {
        point.distance = +this.calcDistance(this.currentLocation, { lat: point.lat, lng: point.lng }) as number;
        if (this.map.getBounds()?.contains({ lat: point.lat, lng: point.lng })) {
          this.nearbyLocations.push(point);
        }
      });
      this.nearbyLocations.sort((loc1, loc2) => (loc1.distance as number) - (loc2.distance as number));
    }
  }

  private calcDistance(userLocation: any, pointOfSale: any) {
    return google.maps.geometry.spherical.computeDistanceBetween(userLocation, pointOfSale).toFixed(2);
  }

  private handleNavigatorLocationPermission() {
    this.setSwitzerlandPosition();
    navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
      permissionStatus.onchange = () => {
        if (permissionStatus.state === 'granted') {
          debug("Location permission granted");
          this.setMyCurrentPosition();
          this.pointOfSaleProvider.dispatchSetUserLocationEnabled(true);
        } else {
          this.pointOfSaleProvider.dispatchSetUserLocationEnabled(false);
        }
      };
    });
  }

  private setMyCurrentPosition() {
    if (navigator.geolocation) {
      debug("watchPosition call handler set");
      this.watchId = navigator.geolocation.watchPosition(
        (position) => {
          debug("Position is available")
          this.pointOfSaleProvider.dispatchSetUserLocationEnabled(true);
          this.currentLocation = { lat: position.coords.latitude, lng: position.coords.longitude };
          if(!this.currentLocationMarker){
            this.map.setCenter(this.currentLocation);
            this.map.setZoom(15);
            this.currentLocationMarker = new google.maps.Marker({
              position: this.currentLocation,
              map: this.map,
              icon: this.currentLocationIcon,
              zIndex: 99
            });
          }
          this.currentLocationMarker.setPosition(this.currentLocation);
        }, 
        (error) => {
          debug("Current Position is not available");
          debug(error);
          if(error.code === error.PERMISSION_DENIED){
            this.setSwitzerlandPosition();
          }
        },
        {
          enableHighAccuracy: true,
          maximumAge: 100,
          timeout : 5000
        }
      );
    } else {
      debug("navigator.geolocation is not available");
      this.pointOfSaleProvider.dispatchSetUserLocationEnabled(false);
      this.setSwitzerlandPosition();
    }
  }

  private setClusters() {
    const googleMap = this.map as google.maps.Map;
    const renderer = { render({ count, position }:any) {
        const color = "rgba(107, 179, 221, 0.8)";
        // create svg url with fill color
        const svg = window.btoa(`
                <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
                <circle cx="120" cy="120" opacity=".8" r="70" />    
                </svg>`);
          return new google.maps.Marker({
              position,
              icon: {
                  url: `data:image/svg+xml;base64,${svg}`,
                  scaledSize: new google.maps.Size(100, 100),
              },
              label: {
                  text: String(count),
                  color: "rgba(255,255,255,0.9)",
                  fontSize: "12px",
              },
              title: `Cluster of ${count} markers`,
              zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
          });
    }}
    this.markerCluster = new MarkerClusterer({ map: googleMap, markers: this.markers , renderer });
    this.markerCluster.addMarkers(this.markers, true);
  }

  public posItemClickHandler(pointOfSale: IPointOfSaleVM) {
    this.renderPointOfSaleDetails(pointOfSale);
  }

  public onPointOfSalesFromSearchClick(pointOfSale: IPointOfSaleVM) {
    this.renderPointOfSaleDetails(pointOfSale);
    
    this.map.setCenter({ lat: pointOfSale.lat - 0.0038, lng: pointOfSale.lng - 0.0002});
    this.map.setZoom(15);
  }

  private renderPointOfSaleDetails(pos: IPointOfSaleVM) {
    debug("renderPointOfSaleDetails start");
    this.pointOfSaleProvider.dispatchApplyFilterVisibility(false);   
    this.renderDetails = true;
    this.pointOfSale = pos
    try {
      this.isValidDistance = +this.calcDistance(this.currentLocation, pos) <= 150;
    } catch (error) {
      debug("Error while checking the valid distance",error)
      this.isValidDistance = false;
    }
    this.search.showSelectedPos = true;
    this.ref.detectChanges();
    debug("renderPointOfSaleDetails end");
  }

  private setSwitzerlandPosition() {
    const switzerland_center = {
      lat: 46.8182,
      lng: 8.2275
    }; // Switzerland lat and lng
    this.map.setCenter(switzerland_center);
    this.map.setZoom(8);
  }

  override onBackClicked(): void {
    StoreFinderUtils.switchToAppAndCloseWindow();
  }

  public isUserAuthenticated() {
    if (sessionStorage.getItem('isTwintUser')) {
      return JSON.parse(sessionStorage.getItem('isTwintUser') as string);
    }
    return false;
  }
  
  override ngOnDestroy(): void {
    super.ngOnDestroy();
    if (navigator.geolocation && this.watchId) {
      navigator.geolocation.clearWatch(this.watchId)
    }
  }
  onPointOfSaleClick(pointOfSale: IPointOfSaleVM) {
    this.renderPointOfSaleDetails(pointOfSale);
    this.map.setCenter({ lat: pointOfSale.lat - 0.0038, lng: pointOfSale.lng - 0.0002});
    this.map.setZoom(15);
  }

  hideFilter() {
    this.pointOfSaleProvider.dispatchApplyFilterVisibility(false);  
  }

  toggleMobileTile(showTile: boolean) {
    this.showTile = showTile;
  }
}
