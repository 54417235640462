import { Component, Input, Output, EventEmitter, NgZone } from '@angular/core';
import { Observable, take } from 'rxjs';
import { IPointOfSaleVM, PointOfSalesProvider } from '@modeso/tsf-lib-pointofsales-fe';
import { SessionProvider } from '../../state/session-state/provider/session.provider';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'tsf-main-tile',
  templateUrl: './main-tile.component.html',
  styleUrls: ['./main-tile.component.scss']
})
export class MainTileComponent {
  @Input() pointOfSale!: IPointOfSaleVM;
  @Input() isValidDistance!: boolean;
  @Output() backToList: EventEmitter<void> = new EventEmitter();
  @Output() routeClicked: EventEmitter<void> = new EventEmitter();
  @Output() isSwiped: EventEmitter<boolean> = new EventEmitter();
  showMoreDetails: boolean = false;
  isRedirectedToPayment:boolean = false;
  locationEnabled$: Observable<boolean>;

  constructor(
    private router: Router,
    private ngZone: NgZone,
    private sessionProvider: SessionProvider,
    private pointOfSaleProvider: PointOfSalesProvider
  ) {
     this.locationEnabled$ = this.pointOfSaleProvider.getUserLocationState$();
  }

  onBackClicked() {
    if(this.showMoreDetails) {
      this.onReturnClicked();
    } else {
      this.backToList.emit();
    }

  }

  onRouteClicked() {
    this.routeClicked.emit();
  }

  onSwipe($event: any) {
    this.isSwiped.emit(true)
    if( Math.abs($event.deltaY) > 40 ){
      if($event.deltaY > 0 ) {
        this.showMoreDetails = false;
        setTimeout(()=>{
          // show icon again after finishing swipe down
          this.isSwiped.emit(false);
        },400);
      } else {
        this.showMoreDetails = true;
      }
    }
  }

  onReturnClicked(){
    this.showMoreDetails = false;
    setTimeout(()=>{
      this.isSwiped.emit(false);
    },400);
  }

  public isUserAuthenticated() {
    if (sessionStorage.getItem('isTwintUser')) {
      return JSON.parse(sessionStorage.getItem('isTwintUser') as string);
    }
    return false;
  }

  public redirectToPaymentPartner(posId: string) {
    if(!this.isAllowedToCheckOut()) {
      this.router.navigate(['/session/expired/timeout']);
      return;
    }
    this.isRedirectedToPayment = true;
    this.sessionProvider.dispatchRedirectToPaymentPartner(posId);

    this.sessionProvider.getError$().pipe(take(2)).subscribe(
      (errorResponse: HttpErrorResponse | undefined) => {
        if (errorResponse!.status === 500) {
          this.ngZone.run(()=>{
            this.router.navigate(['/paymentpartnererror']);
          });
        }
      }
    )
    this.sessionProvider.getCheckInUrl$().pipe(take(2)).subscribe(
      (checkInUrl: string)=> {
        if (checkInUrl) {
          const protocol = localStorage.getItem('protocol');
          const device = localStorage.getItem('device');

          if (!protocol || !device) {
            console.error('Protocol or device information is missing');
            return; // Optionally handle this scenario
          }

          const updatedUrl = this.updateUrlWithQueryParam(checkInUrl, device, protocol);

          window.location.href = updatedUrl;
          this.isRedirectedToPayment = false;
        }
      }
    )
  }
  get Object() {
    return Object;
  }

  private updateUrlWithQueryParam(checkInUrl: string, device: string, protocol: string) {
    let queryParamKey;
    if (device === 'ios') {
      queryParamKey = 'returnAppScheme';
    } else {
      queryParamKey = 'returnAppPackage';
    }

    let urlParts = checkInUrl.split('#');
    let baseUrl = urlParts[0];
    let hashFragment = urlParts[1] || '';

    let searchParams = new URLSearchParams(baseUrl.split('?')[1]);
    searchParams.set(queryParamKey, protocol); // Set or update the query parameter

    baseUrl = baseUrl.split('?')[0] + '?' + searchParams.toString();
    let updatedUrl = baseUrl + (hashFragment ? '#' + hashFragment : '');

    return updatedUrl;
  }

  private isAllowedToCheckOut() {
    const savedCheckinDate = sessionStorage.getItem('checkInDate');
    // if store is empty load checkin date from local storage
    const checkInDate = savedCheckinDate ?  new Date(savedCheckinDate) : null;
    if (!checkInDate) {
      return false;
    }
    const offset15min = 60 * 60 * 1000;
    try {
      if (new Date(checkInDate).getTime() + offset15min > new Date().getTime()) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  redirectToWebsite(website: string) {
    window.location.href = website
  }
}
