<div class="page-container" >
    <img class="twint-logo" src="assets/twint_logo_q_pos_bg.png" />
    <div class="container">
        <img class="denied-icon" src="assets/icon-denied.png"/>
        <div class="title">
            {{ 'tsf_payment_partner_error_title' | injectedText | async }}
        </div>
        <div class="content">
            {{ 'tsf_payment_partner_error_message' | injectedText | async }}
        </div>
        <div class="btn">
            <button mat-button class="storefinder-button storefinder-black-button" (click)="onClick()">
                {{ 'tsf_back_to_twint_btn_text' | injectedText | async }}
            </button>
        </div>
    </div>
</div>