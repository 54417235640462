
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// import { IAuthHandlerDelegate } from 'tsf-lib-admin-fe';
import Debug from 'debug';
const debug = Debug('dgoods:admin:SessionOrchestrator');

@Injectable({
    providedIn: 'root'
})
export class SessionOrchestrator {

    constructor(private router: Router) { }

    onSessionExpired() : void {
        debug(this.router.url);
        if (!this.router.isActive('/session/expired/timeout', false)) {
            debug('onSessionExired redirect to login');
            this.router.navigateByUrl('session/expired/timeout');
        }

    }

}
