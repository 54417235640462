<div *ngIf="pointOfSale"  class="container-wrapper"  
(swipe)="onSwipe($event)" 
>
  <div id="line-wrapper">
    <span class="line"></span>
  </div>
  <div class="container-wrapper__info-container">
    <tsf-point-of-sale-item
    [ngClass]="{'hide-arrow-pos': showMoreDetails}"
    (routeToPosClick)="onRouteClicked(pointOfSale)"
    [pointOfSale]="pointOfSale"></tsf-point-of-sale-item>

    <div class="scrollable-info" *ngIf="showMoreDetails">
      <div class="anchor-row"  [ngClass]="{ disabled: (locationEnabled$ | async) === false}"  >
        <img src="assets/twint-icons-directions.svg"/>
        <a style="margin-left: 16px;" (click)="onRouteClicked(pointOfSale)">
            {{ 'tsf_pos_details_desktopview_showroute_btn' | injectedText | async }}
        </a>
      </div>
      <div class="info-items" *ngIf="Object.keys(pointOfSale.openingHours)?.length">
        <img src="assets/opening_hours.svg" style="align-self: flex-start; margin-top: 10px;"/>
        <tsf-pos-opening-hours  [openingHours]="pointOfSale.openingHours"></tsf-pos-opening-hours>
      </div>
      <div class="info-items">
        <img src="assets/location.svg" /><span class="more-info-span">{{pointOfSale.pointAddress.street}}, {{pointOfSale.pointAddress.zipCode}} {{pointOfSale.pointAddress.city}}</span>
      </div>
      <div *ngIf="pointOfSale.website" class="info-items">
        <img src="assets/website.svg" /><a class="more-info-span"
         (click)="redirectToWebsite(pointOfSale.website )" style="text-decoration: underline;">{{ pointOfSale.website }}</a>
      </div>
      <div *ngIf="pointOfSale.phone" class="info-items">
        <img src="assets/telephone.svg" /> <a class="more-info-span" href="tel:{{ pointOfSale.phone }}">{{ pointOfSale.phone }}</a>
      </div>
      
    </div>
    <div class="action-wrapper">
      <span class="disabling-checkout-text" *ngIf="isUserAuthenticated() && 
      (!isValidDistance || (locationEnabled$ | async) === false)">
        {{'tsf_pos_tile_checkout_button_disbaling_reason' | localize | async}}
      </span>
      <button
      class="storefinder-button storefinder-black-button"
      [disabled]="!isValidDistance || isRedirectedToPayment"
      *ngIf="isUserAuthenticated()"
      (click)="redirectToPaymentPartner(pointOfSale.pointofsaleId)"
      >
        {{ pointOfSale.categoryText }}
      </button>
      <button
      class="storefinder-button storefinder-white-button"
      style="margin-top: 8px"
      (click)="onBackClicked()"
      >
      {{ 'tsf_details_view_back_btn' | injectedText | async }}
    </button>
    </div>
  </div>
</div>
