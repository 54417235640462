export class StoreFinderUtils {

  public static switchToAppAndCloseWindow() {
    const device = localStorage.getItem('device');
    const protocol = localStorage.getItem('protocol');
    if (!protocol || !device) {
      return;
    }
    if (device === 'ios') {
      const redirectURL = `${protocol}://ch.twint.wallet`;
      window.location.href = redirectURL;
    } else if (device === 'android') {
      const redirectURL = `intent://ch.twint.wallet#Intent;package=${protocol};scheme=twint;end`;
      window.location.href = redirectURL;
    }
  }

  public static getLanguage() {
    const browserLanguage = navigator.language;
    if (browserLanguage.includes('de')) {
      return 'de-ch';
    } else if (browserLanguage.includes('fr')) {
      return 'fr-ch';
    } else if (browserLanguage.includes('it')) {
      return 'it-ch';
    } else {
      return 'en-us';
    }
  }
}