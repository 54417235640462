<div class="wrapper">
    <ng-container *ngIf="!isSwiped">
        <div *ngIf="isUserAuthenticated()">
            <tsf-back-button [delegate]="this" [buttonText]="'tsf_back_to_twint_btn_text' | localize | async">
            </tsf-back-button>
        </div>
    </ng-container>
   
    <div class="container" [ngClass]="{'mobile': isMobile}">
  
        <div class="overlay" *ngIf="isMobile && !hasCategory && filterNotApplied" (click)="hideFilter()">
            <div class="title">{{'tsf_mobile_overlay_title_what_you_search_for' | injectedText | async}}</div>
            <tsf-pos-category-filter></tsf-pos-category-filter>
            <a class="action-link" (click)="setAllCategories()"><span 
                *ngIf="!hasSelectedCategory; else filteredByCategory;">{{'tsf_mobile_overlay_apply_all' | injectedText | async}}</span></a>
            <ng-template #filteredByCategory>
                <a class="action-link" (click)="viewResult()">
                    <span>{{'tsf_mobile_overlay_view_see_results' | injectedText | async}}</span>
                </a>
            </ng-template>
        </div>
        <div class="search" [ngClass]="{'mobile': isMobile}">
            <tsf-search #search 
            [allPointOfSales]="locations"
            [pointOfSale]="pointOfSale"
            [nearbyLocations] = "nearbyLocations"
            [locationEnabled]="isLocationEnabled"
            (pointOfSaleClick)="onPointOfSaleClick($event)"
            (emitRouteToPos)="onRouteClicked($event)"
            (emitShowTile)="toggleMobileTile($event)"
            ></tsf-search>
         
        </div>
    
        <div #map class="map" [ngClass]="{'map-mobile': isMobile}"></div>
        <ng-container *ngIf="isMobile">
        
            <div class="absolute-div" *ngIf="showTile && (( (showFilter$ | async) === false && !filterNotApplied) 
            || hasCategory && (showFilter$ | async) === false)">
                <button mat-button class="jump-back-btn" (click)="jumpBackToMyCurrentPosition()"
                *ngIf="isLocationEnabled && !isSwiped">
                     <mat-icon>my_location</mat-icon>
                </button>
              
                <div class="info-container">
                    <tsf-point-of-sale-tile 
                        *ngIf="!(renderDetails && search.showSelectedPos) "
                        [pointOfSales]="nearbyLocations"
                        [allPointOfSales]="locations"
                        [tileTitle]="tileTitle"
                        (routeToPosClicked)="onRouteClicked($event)"
                        (pointOfSaleClick)= "posItemClickHandler($event)"
                        (pointOfSaleFromSearchClick) = "onPointOfSalesFromSearchClick($event)">
                    </tsf-point-of-sale-tile>
                    <tsf-main-tile *ngIf="renderDetails && search.showSelectedPos" 
                        [isValidDistance]="isValidDistance" 
                        [pointOfSale]="pointOfSale"
                        (routeClicked)="onRouteClicked()"
                        (isSwiped)="isTileSwiped($event)"
                        (backToList)="backToList()">
                    </tsf-main-tile>
                </div>
            </div>
        </ng-container>
        <button mat-button class="jump-back-btn desktop" (click)="jumpBackToMyCurrentPosition()"
        *ngIf="isLocationEnabled && !isMobile">
             <mat-icon>my_location</mat-icon>
        </button>
        <div class="activate-location" *ngIf="!isLocationEnabled && !isMobile">
            <span>
                {{ 'tsf_enable_location_msg' | injectedText | async }}
            </span>
        </div>        
    </div>
</div>



