import { Component, Injector, Inject, OnInit } from '@angular/core';
import { BasePage } from './pages/base.page';
import { environment } from '../environments/environment';
import { NavigationService } from './utils/services/navigation.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BasePage implements OnInit {
  title = 'Storefinder';

  constructor(@Inject(DOCUMENT) private document: Document, injector: Injector, navigationService: NavigationService) {
    super(injector, navigationService);
  }

  override ngOnInit() {
    super.ngOnInit()
    this.updateIndexHtmlFile();
  }

  updateIndexHtmlFile(){
    try {
      const script = document.createElement('script');
      script.defer = true;
      script.src = 'https://plausible.io/js/script.js';
      script['data-domain'] = environment.dataDomain;
      this.document.head.appendChild(script);
      console.log('skp appending ok');
    } catch (ex) {
      console.error('Error appending plausible' + ex);
    }
  }
}
