import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProxyComponent } from './components/proxy/proxy.component';
import { LandingBuyComponent } from './pages/landing-buy/landing-buy.component';
import { OneTimeStartUrl } from './pages/one-time-start-url/one-time-start-url.component';
import { PaymentPartnerErrorComponent } from './pages/paymentPartnerError/payment-partner-error.component';
import { SessionExpiredPage } from './pages/session-expired-page/session-expired-page.component';

const routes: Routes = [

  {
    path: ':category/:oneTimeUrl',
    component: OneTimeStartUrl,
  },
  {
    path: 'paymentpartnererror',
    component: PaymentPartnerErrorComponent,
  },
  {
    path: ':category',
    component: ProxyComponent,
  },
  {
    path: 'session/expired/timeout',
    component: SessionExpiredPage,
  },
  {
    path: '**',
    component: LandingBuyComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
