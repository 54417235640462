import { Action, createAction, props } from '@ngrx/store';

export const onRedirectToPaymentPartner = createAction(
  '[TSF redirect payment partners] redirect payment partners',
  props<{ payload: {posId: string} }>()
);
  
export const onRedirectToPaymentPartnerSuccess = createAction(
  '[TSF redirect payment partners] redirect payment partners success',
  props<{ payload: string }>()
);
  
export const onRedirectToPaymentPartnerFailed = createAction(
  '[TSF redirect payment partners] redirect payment partners failed',
  props<{ payload: any }>()
);
  

export interface ActionWithPayload<T> extends Action {
  payload: T;
}
  