import { Injectable } from '@angular/core';
import * as sessionActions from '../actions/session.actions';
import * as fromFeature from '../reducers/session.reducer';
import { Store, select } from '@ngrx/store';
import { filter, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';



@Injectable({
    providedIn: 'root'
})
export class SessionProvider {

  constructor(private store: Store<fromFeature.AppState>) { }

  public dispatchRedirectToPaymentPartner(posId: string) {
    this.store.dispatch(sessionActions.onRedirectToPaymentPartner({payload: {posId}}));
  }

  public getCheckInUrl$(): Observable<string> {
    return this.store.pipe(select(fromFeature.selectFeatureCheckInUrl));
  }

  public getError$(): Observable<HttpErrorResponse | undefined> {
    return this.store.pipe(
      select(fromFeature.selectFeaturePaymentPartnersError),
      filter((error: HttpErrorResponse | undefined) => error != null)
    );
  }

}
