import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DgoodsSessionStoreProvider } from '@modeso/twint-lib-session-fe';
import { of, throwError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, concatMap, delay, retryWhen, take } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private sessionProviders: DgoodsSessionStoreProvider, private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      withCredentials: true,
    });
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 440) {
          this.router.navigateByUrl('/session/expired/timeout');
        }
        throw err;
      }),
      retryWhen((errors) => {
        return errors.pipe(
          concatMap((error) => {
            if (error.status === 401) {
              this.sessionProviders.getNewAccsessToken();
              return of(error.status);
            }
            throw error;
          }),
          take(2),
          delay(1000)
        );
      })
    );
  }
}
