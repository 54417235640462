

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SessionConfigService } from '../session.config';

@Injectable({
  providedIn: 'root',
})
export class SessionService {

  private server = '';
  private readonly sessionMicroServiceName = 'twint';
  private readonly sessionRoute = '/';
  private readonly paymentPartner = 'paymentPartner';

  constructor(
    private http: HttpClient, @Inject(SessionConfigService) private config:any) {
    this.server = config.apiGateway;
  }

  public redirectToPaymentPartner(posId: string): Observable<string> {
    const url = `${this.server}${this.sessionMicroServiceName}${this.sessionRoute}${this.paymentPartner}${this.sessionRoute}${posId}`;
    return this.http.get<string>(url);
  }
}
