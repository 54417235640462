export const environment = {
  production: true,
  maintenance: false,
  modeso: {
    apiGateway: 'https://api.storefinder-pat.twint.ch/api/',
  },
  defaultLanguage: 'de-ch',
  availableLanguages: ['de-ch', 'fr-ch', 'it-ch'],
  enableDebugging: '',
  dataDomain: 'storefinder-pat.twint.ch/'
};
