import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-proxy',
  templateUrl: './proxy.component.html',
  styleUrls: ['./proxy.component.scss']
})
export class ProxyComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute,) { 
    if(this.route.snapshot.url[0]) {
      sessionStorage.setItem('category',this.route.snapshot.url[0].path)
    }
    this.router.navigate(['/']);
  }

  ngOnInit(): void {
  }

}
