import { Component, Input, OnInit } from '@angular/core';

export interface IBackButtonClickedDelegate {
  onBackClicked(): void;
}
@Component({
  selector: 'tsf-back-button',
  templateUrl: './tsf-back-button.component.html',
  styleUrls: ['./tsf-back-button.component.scss']
})
export class TsfBackButtonComponent {
 
  @Input() delegate!: IBackButtonClickedDelegate;
  @Input() buttonText!: string;
  constructor() { }
  onBackButtonClicked(): void {
    if(this.delegate) {
      this.delegate.onBackClicked();
    } 
  }
}
