import { ModuleWithProviders, NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CommonModule } from '@angular/common';
import * as fromReducer from './reducers/session.reducer';
import { SessionConfigService, SessionConfig } from './session.config';
import { SessionService } from './services/session.service';
import { SessionEffects } from './effects/session.effect';

@NgModule({
  providers: [SessionService],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromReducer.modesoFeatureKey, fromReducer.reducer),
    EffectsModule.forFeature([SessionEffects])
  ],
})
export class SessionModule {
  static forRoot(config: SessionConfig): ModuleWithProviders<SessionModule> {
    return {
      ngModule: SessionModule,
      providers: [
        SessionService,
        {
          provide: SessionConfigService,
          useValue: config
        }
      ]
    };
  }
}
