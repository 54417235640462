import { Action, createReducer, on, createSelector } from '@ngrx/store';
import * as sessionActions from '../actions/session.actions';

export const modesoFeatureKey = 'modesoSessionMicroservice';

// State Declarations - START

export interface FeatureState {
  paymentPartnerError: undefined;
  checkInPaymentPartner: string;
}

export interface AppState {
  modesoSessionMicroservice: FeatureState;
}

// State Declarations - END

// Selectors Declarations - START
export const selectFeature = (state: AppState) => state.modesoSessionMicroservice;


export const selectFeaturePaymentPartnersError = createSelector(
  selectFeature,
  (state: FeatureState) => state.paymentPartnerError
);

export const selectFeatureCheckInUrl = createSelector(
  selectFeature,
  (state: FeatureState) => state.checkInPaymentPartner
);

// Selectors Declarations - END

// Reducer Declarations - START

export const initialState: FeatureState = {
  paymentPartnerError:undefined,
  checkInPaymentPartner: ""
};

const sessionReducer = createReducer(
  initialState,
  on(sessionActions.onRedirectToPaymentPartner, 
    (state) => ({ ...state , checkInPaymentPartner: "" })),
  on(sessionActions.onRedirectToPaymentPartnerSuccess, 
    (state, action) => ( { ...state , checkInPaymentPartner: action.payload, paymentPartnerError:undefined })),
  on(sessionActions.onRedirectToPaymentPartnerFailed, 
    (state, action) => ( { ...state , paymentPartnerError: action.payload }))
);

export function reducer(state: FeatureState | undefined, action: Action) {
  return sessionReducer(state, action);
}
