import { OnInit, Component, Injector, OnDestroy } from '@angular/core';
import { environment } from '../../environments/environment';
import Debug from 'debug';
import { Subscription } from 'rxjs';
import { IBackButtonClickedDelegate } from '@modeso/tsf-lib-core-fe';
import { NavigationService } from '../utils/services/navigation.service';
import { LocalizationStoreProvider } from '@modeso/modeso-lib-localization-fe';
import { InjectedTextProvider } from '@modeso/modeso-lib-core-fe';
import { ActivatedRoute } from '@angular/router';
const debug = Debug('modeso:template:BasePage');

@Component({
  selector: 'modeso-base-page',
  template: `<ng-content></ng-content>`,
})
export class BasePage implements OnInit, OnDestroy, IBackButtonClickedDelegate {
  protected subscriptions: Array<Subscription> = [];
  localizationStoreProvider: any;
  injectedTextProvider: InjectedTextProvider;
  activatedRoute: ActivatedRoute;

  constructor(injector: Injector,  private navigationService: NavigationService) {
    debug('base loaded');
    this.localizationStoreProvider = injector.get(LocalizationStoreProvider);
    this.injectedTextProvider = injector.get(InjectedTextProvider);
    this.activatedRoute = injector.get(ActivatedRoute);
    this.localizationStoreProvider.addObserver("modeso_core", this.injectedTextProvider);
    addEventListener('contextmenu', (event) => { event.stopPropagation(); event.preventDefault()});
  }
  onBackClicked(): void {
    this.navigationService.back();
  }

  ngOnInit(): void {
    this.setDebuggingMode();
    this.localizationStoreProvider.loadLocalizations();
  }

  setDebuggingMode() {
    localStorage['debug'] = environment.enableDebugging;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscribe) => {
      subscribe.unsubscribe();
    });
  }
}
