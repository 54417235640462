import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SessionService } from '../services/session.service';
import { mergeMap, map, catchError,  tap } from 'rxjs/operators';
import * as appActions from '../actions/session.actions';
import { of } from 'rxjs';
import Debug from 'debug';


const debug = Debug('modeso:tsf-project-storefinder:SessionEffects');


@Injectable()
export class SessionEffects {

  constructor(private actions$: Actions, private service: SessionService) { }
 
  onRedirectToPaymentPartner$ = createEffect(
    () => this.actions$.pipe(
      ofType(appActions.onRedirectToPaymentPartner.type),
      mergeMap((payload: any) => {
        return this.service.redirectToPaymentPartner(payload.payload.posId).pipe(
          map( response => {
            return appActions.onRedirectToPaymentPartnerSuccess({ payload: response });
          }),
          catchError( error => {
            return of(appActions.onRedirectToPaymentPartnerFailed({ payload: error }));
          })
        );
      })
    )
  );

  errorOnRedirectToPaymentPartner$ = createEffect(
    () => this.actions$.pipe(
      ofType(appActions.onRedirectToPaymentPartnerFailed.type),
      tap((action: any) => this.handleOnLoadAllErrors(action.payload)),
    ), { 
      dispatch: false 
    }
  );

  public handleOnLoadAllErrors(error:any) {
    debug(error);
    return error;
  }

}